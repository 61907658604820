.logo-container {
  padding: 20px 0 0 20px;
  margin-bottom: 30px;

  img {
    width: 300px;
  }

  @media (max-width: 900px) {
    text-align: center;
  }
}

.img-container {
  text-align: center;
}

.img-404 {
  width: 90%;
  max-width: 700px;
}

.img-403 {
  width: 90%;
  max-width: 700px;
}

.img-429 {
  width: 90%;
  max-width: 700px;
}

.img-500 {
  width: 90%;
  max-width: 900px;
}

.message {
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.button {
  padding: 12px;
  border-radius: 5px;
  background-color: #1c80b8;
  text-decoration: none;
  color: #fff;
  font-size: 15px;

  &:hover {
    background-color: #1f8ecc;
  }

  &.small {
    padding: 10px;
    font-size: 13px;
  }
}

.button-container {
  margin-top: 25px;
  margin-bottom: 25px;
}
